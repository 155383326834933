import { HOTELS_INFO } from "../configuration";

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

export const ConditionalElseWrapper = ({
    condition,
    wrapperTrue,
    wrapperFalse,
    children,
}) => (condition ? wrapperTrue(children) : wrapperFalse(children));

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const formatNumberCommasFromDict = (dict, key) =>
    ((dict ?? {})[key] ?? 0).toLocaleString() ?? "";

// No options: 1456.56 = 1,456.56
// Options: {
//      maxDecimals: (Number) max decimals allowed (defaults to 0)
//      minDecimals: (Number) min decimals allowed (defaults to 0)
//      left: (String) currency: adds currency symbol to the left | "custom unit" on the left
//      right: (String) currency: adds currency symbol to the right | "custom unit" on the rigth
// }
// returns "split" ? (Object) {left: "left unit", number: "formatted number", right: "right unit"} | (String) "formatted number"
const formatNumberToString = (
    hotel_id,
    number,
    options = {},
    split = false
) => {
    const region = HOTELS_INFO[hotel_id].region;

    const formatterOptions = {
        maximumFractionDigits: options.maxDecimals || 0,
        minimumFractionDigits: options.minDecimals || 0,
    };

    let result;

    if (typeof number === "string") {
        result = split ? { number } : number;
    } else {
        let formatter = new Intl.NumberFormat(region, formatterOptions);
        result = split
            ? { number: formatter.format(number) }
            : formatter.format(number);
    }

    if (options.left) {
        let left = "";
        if (options.left === "currency") {
            left = HOTELS_INFO[hotel_id].currency ?? "€";
        } else {
            left = options.left;
        }

        if (split) result.left = left;
        else result = left + result;
    }

    if (options.right) {
        let right = "";
        if (options.right === "currency") {
            right = options.brackets
                ? ` (${HOTELS_INFO[hotel_id].currency})`
                : HOTELS_INFO[hotel_id].currency;
        } else {
            right = options.right;
        }

        if (split) result.right = right;
        else result = result + right;
    }
    return result;
};

export { formatNumberToString as fns };

export const cero = (deg) => ("0" + deg).slice(-2);
