import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Checkbox,
    CircularProgress,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import EChartsReact from "echarts-for-react";

import TableHeaderCell from "../components/TableHeaderCell";
import ToggleButtonTitled from "../components/ToggleButtonTitled";
import SmallPercentage from "../components/SmallPercentage";
import DataLoader from "../components/DataLoader";

import Icons from "../helpers/icons";
import {
    DATATYPE_CHECKIN,
    DATATYPE_CHECKOUT,
    DATATYPE_RESDATE,
} from "../helpers/filters";

const round = (num) => Math.round(num * 100) / 100;

const EventsImpactScreen = () => {
    const [DataBy, setDataBy] = useState(DATATYPE_RESDATE);

    const [eventsData, setEventsData] = useState([]);
    const [revenue, setRevenue] = useState([
        { day: "2024-01-15", v: 1000 },
        { day: "2024-01-16", v: 1500 },
    ]);
    const [lastYearRevenue, setLastYearRevenue] = useState([
        { day: "2024-01-15", v: 1500 },
        { day: "2024-01-16", v: 1000 },
    ]);

    const { dataSimple } = useSelector((state) => state);

    const [Events, setEvents] = useState([]);
    useEffect(() => {
        if (dataSimple.status === "loaded") {
            setEvents(dataSimple.data.Events);
        }
    }, [dataSimple]);

    const { dataBookings } = useSelector((state) => state);

    useEffect(() => {
        if (dataBookings.status === "loaded") {
            const today = new Date();
            const oneYearAgo = new Date(
                today.getFullYear() - 1,
                today.getMonth(),
                today.getDate()
            );
            const twoYearAgo = new Date(
                today.getFullYear() - 2,
                today.getMonth(),
                today.getDate()
            );
            const oneYearFromNow = new Date(
                today.getFullYear() + 1,
                today.getMonth(),
                today.getDate()
            );

            let aux = dataBookings.data
                .sort((a, b) => new Date(a[DataBy]) - new Date(b[DataBy]))
                .reduce((acc, n) => {
                    if (n[DataBy]) {
                        const bookingDate = new Date(n[DataBy]);
                        if (
                            bookingDate >= oneYearAgo &&
                            bookingDate <= oneYearFromNow
                        ) {
                            let d = new Date(n[DataBy])
                                .toISOString()
                                .slice(0, 10);
                            acc[d] =
                                d in acc ? acc[d] + n.TotalPrice : n.TotalPrice;
                        }
                    }
                    return acc;
                }, {});

            const revenueData = Object.entries(aux).map(([day, value]) => ({
                day,
                v: value,
            }));

            setRevenue(revenueData);

            // Last year
            let auxLY = dataBookings.data
                .sort((a, b) => new Date(a[DataBy]) - new Date(b[DataBy]))
                .reduce((acc, n) => {
                    if (n[DataBy]) {
                        const bookingDate = new Date(n[DataBy]);
                        if (
                            bookingDate >= twoYearAgo &&
                            bookingDate <= new Date()
                        ) {
                            let d = new Date(n[DataBy])
                                .toISOString()
                                .slice(0, 10);
                            acc[d] =
                                d in acc ? acc[d] + n.TotalPrice : n.TotalPrice;
                        }
                    }
                    return acc;
                }, {});

            const revenueDataLY = Object.entries(auxLY).map(([day, value]) => ({
                day: new Date(
                    new Date(day).getUTCFullYear() + 1,
                    new Date(day).getUTCMonth(),
                    new Date(day).getUTCDate()
                )
                    .toISOString()
                    .slice(0, 10),
                v: value,
            }));

            setLastYearRevenue(revenueDataLY);
        }
    }, [dataBookings, DataBy]);

    const getAverages = (revenueData) => {
        const sums = {};
        const counts = {};
        revenueData.forEach(({ day, v }) => {
            const month = day.substring(0, 7); // "YYYY-MM"
            if (sums[month]) {
                sums[month] += v;
                counts[month] += 1;
            } else {
                sums[month] = v;
                counts[month] = 1;
            }
        });

        const averages = Object.keys(sums).map((month) => {
            return {
                month,
                average: sums[month] / counts[month],
            };
        });

        return averages;
    };

    const getOptions = () => ({
        tooltip: {
            trigger: "axis",
        },
        xAxis: {
            type: "time",
            boundaryGap: false,
            splitArea: {
                show: true,
            },
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                name: "Revenue",
                type: "line",
                data: revenue.map((r) => [r.day, round(r.v)]),
                smooth: false,
                symbol: "none",
                markArea: {
                    itemStyle: {
                        color: "rgba(255, 233, 204, 1)",
                        opacity: 0.75,
                    },
                    data: Events.filter(
                        (e) => !eventsToHide.includes(e.Name)
                    ).map((event) => {
                        const startDate = new Date(event.FromDate);
                        const endDate = new Date(
                            event.ToDate || event.FromDate
                        );
                        endDate.setDate(endDate.getDate() + 1);

                        return [
                            {
                                name: event.Name,
                                xAxis: startDate.toISOString().slice(0, 10),
                            },
                            {
                                xAxis: endDate.toISOString().slice(0, 10),
                            },
                        ];
                    }),
                },
            },
            {
                name: "Revenue one year ago",
                type: "line",
                data: lastYearRevenue.map((r) => [r.day, round(r.v)]),
                smooth: false,
                symbol: "none",
            },
            {
                name: "Monthly Average Revenue",
                type: "line",
                data: getAverages(revenue).map((avg) => [
                    `${avg.month}-15`,
                    round(avg.average),
                ]),
                smooth: true,
                symbol: "circle",
                symbolSize: 6,
                itemStyle: {
                    // color: "red",
                },
            },
        ],
        dataZoom: [
            {
                type: "inside",
            },
            {
                start: 0,
                end: 10,
            },
        ],
        legend: {},
    });

    const [eventsToHide, setEventsToHide] = useState([]);
    const handleCheckboxChange = (eventName, isChecked) => {
        setEventsToHide((prevEventsToHide) => {
            if (isChecked) {
                return prevEventsToHide.filter((event) => event !== eventName);
            } else {
                return [...prevEventsToHide, eventName];
            }
        });
    };

    return (
        <Container>
            <DataLoader Reservations SimpleData />
            <br />
            {dataBookings.status !== "loaded" ? (
                <CircularProgress />
            ) : (
                <>
                    <ToggleButtonTitled
                        values={{
                            [DATATYPE_RESDATE]: "Booked on date",
                            [DATATYPE_CHECKIN]: "Checked in date",
                            [DATATYPE_CHECKOUT]: "Checked out date",
                        }}
                        valueSet={DataBy}
                        onChange={(e, v) => {
                            setDataBy(v);
                        }}
                    />
                    <br />

                    <Typography>
                        Number of events this year:{" "}
                        {
                            (Events ?? []).filter(
                                (e) =>
                                    new Date(e.FromDate) >=
                                    new Date(new Date().getUTCFullYear(), 0, 1)
                            ).length
                        }
                    </Typography>
                    <EChartsReact
                        option={getOptions()}
                        style={{ height: "400px", width: "100%" }}
                    />
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableHeaderCell>
                                    {eventsToHide.length > 0 && (
                                        <IconButton
                                            onClick={() => setEventsToHide([])}
                                        >
                                            <Icons.EyeOn />
                                        </IconButton>
                                    )}
                                </TableHeaderCell>
                                <TableHeaderCell>Event Name</TableHeaderCell>
                                <TableHeaderCell>Category</TableHeaderCell>
                                <TableHeaderCell>From</TableHeaderCell>
                                <TableHeaderCell>To</TableHeaderCell>
                                <TableHeaderCell></TableHeaderCell>
                                <TableHeaderCell>Revenue</TableHeaderCell>
                                <TableHeaderCell>Last Year R.</TableHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(Events ?? []).map((e) => (
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={
                                                !eventsToHide.includes(e.Name)
                                            }
                                            onChange={(e_) =>
                                                handleCheckboxChange(
                                                    e.Name,
                                                    e_.target.checked
                                                )
                                            }
                                        />
                                    </TableCell>

                                    <TableCell>{e.Name}</TableCell>
                                    <TableCell>
                                        {e.Category} {e.Subcategory}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(e.FromDate)
                                            .toISOString()
                                            .slice(0, 10)}
                                    </TableCell>
                                    <TableCell>
                                        {new Date(e.ToDate)
                                            .toISOString()
                                            .slice(0, 10)}
                                    </TableCell>
                                    <TableCell>
                                        <SmallPercentage
                                            actual={revenue
                                                .filter(
                                                    (f) =>
                                                        new Date(f.day) >=
                                                            new Date(
                                                                e.FromDate
                                                            ) &&
                                                        new Date(f.day) <=
                                                            new Date(e.ToDate)
                                                )
                                                .reduce(
                                                    (acc, v) => acc + v["v"],
                                                    0
                                                )}
                                            old={lastYearRevenue
                                                .filter(
                                                    (f) =>
                                                        new Date(f.day) >=
                                                            new Date(
                                                                e.FromDate
                                                            ) &&
                                                        new Date(f.day) <=
                                                            new Date(e.ToDate)
                                                )
                                                .reduce(
                                                    (acc, v) => acc + v["v"],
                                                    0
                                                )}
                                            brackets={false}
                                            tooltip={false}
                                            bubble={true}
                                            bubbleRound={true}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {console.log({
                                            revenue,
                                            lastYearRevenue,
                                        })}
                                        {revenue
                                            .filter(
                                                (f) =>
                                                    new Date(f.day) >=
                                                        new Date(e.FromDate) &&
                                                    new Date(f.day) <=
                                                        new Date(e.ToDate)
                                            )
                                            .reduce((acc, v) => acc + v["v"], 0)
                                            .toLocaleString()}
                                    </TableCell>
                                    <TableCell>
                                        {lastYearRevenue
                                            .filter(
                                                (f) =>
                                                    new Date(f.day) >=
                                                        new Date(e.FromDate) &&
                                                    new Date(f.day) <=
                                                        new Date(e.ToDate)
                                            )
                                            .reduce((acc, v) => acc + v["v"], 0)
                                            .toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </Container>
    );
};

export default EventsImpactScreen;
