import React, { useEffect } from "react";

import { Container, Grid, Typography } from "@mui/material";

import { clean } from "../actions/PDFExport";

import Tables2Print from "../components/Tables2Print";
import { useDispatch } from "react-redux";

const PDFExportScreen = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(clean());
        };
    }, []);

    return (
        <>
            <Container maxWidth={false}>
                <Grid container>
                    <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                        <Typography variant="h4">PDF Export</Typography>
                    </Grid>
                </Grid>

                <Tables2Print />
            </Container>
            <br />
            <br />
            <br />
        </>
    );
};
export default PDFExportScreen;
