import { createTheme } from "@mui/material/styles";
import Colours from "./helpers/colours";

const FONT_SIZE = 16;

export const lightTheme = createTheme({
    palette: {
        type: "light",
        mode: "light",
        primary: {
            main: Colours.primary,
        },
        secondary: {
            main: Colours.secondary,
        },
        error: {
            main: Colours.error,
        },
        warning: {
            main: Colours.warning,
        },
        info: {
            main: Colours.info,
        },
        divider: Colours.divider,
        background: {
            default: Colours.backgroundWhite,
        },
        text: {
            primary: Colours.textColour,
        },
    },
    typography: {
        fontFamily: "Montserrat",
        fontSize: FONT_SIZE,
    },
});

export const darkTheme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#408C44",
            light: "#57B25C",
            dark: "#244F26",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#FF931E",
        },
        error: {
            main: "#BA2926",
        },
        warning: {
            main: "#FFD747",
        },
        info: {
            main: "#5B59C5",
        },
        background: {
            default: "#303030",
            paper: "#424242",
        },
        text: {
            primary: "#ffffff",
            secondary: "rgba(255,255,255,0.7)",
            disabled: "rgba(255,255,255,0.5)",
            hint: "rgba(255,255,255,0.5)",
        },
    },
    typography: {
        fontFamily: "Montserrat",
        fontSize: FONT_SIZE,
    },
});
