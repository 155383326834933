import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import {
    Button,
    Box,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    Tooltip,
    Typography,
} from "@mui/material";

import HoverPaper from "./shared/HoverPaper";
import CheckShow from "./shared/CheckShow";

import Icons from "../helpers/icons";
import Colours from "../helpers/colours";
import {
    CANCELLATIONS_FILTER,
    DATATYPE_CHECKIN,
    DATATYPE_RESDATE,
    DISPLAY_ELEMENT_HELPER,
    getMetricName,
} from "../helpers/filters";

import SmallPercentage from "./SmallPercentage";
import NoDataRow from "./NoDataRow";

import { ARO_ADMIN, ARO_ALL, HOTELS_INFO } from "../configuration";
import InfoIcon from "./InfoIcon";
import { fns } from "../helpers/common";
import EventsCarouselContainer from "./events/EventsCarouselContainer";
import EventsTimeCarousel from "./events/EventsTimeCarousel";
import { CheckHideBool } from "./shared/CheckHide";

const CustomTooltip = (props) => (
    <Tooltip
        {...props}
        componentsProps={{
            tooltip: {
                sx: {
                    bgcolor: "white",
                    color: "black",
                    borderTop: `2px solid ${Colours.primary}`,
                    borderRadius: "4px",
                    boxShadow: "0px 0px 12px rgba(0,0,0,0.1)",
                },
            },
            arrow: {
                sx: {
                    color: "white",
                    fontSize: "1.75em",
                    "&:before": {
                        border: `2px solid ${Colours.primary}`,
                        borderBottomColor: "transparent",
                        borderRightColor: "transparent",
                    },
                },
            },
        }}
        arrow
    />
);

//   _____ _                           _
//  | ____| | ___ _ __ ___   ___ _ __ | |_
//  |  _| | |/ _ \ '_ ` _ \ / _ \ '_ \| __|
//  | |___| |  __/ | | | | |  __/ | | | |_
//  |_____|_|\___|_| |_| |_|\___|_| |_|\__|
const DataElement = ({ name, value, valueOld, fromDay, toDay }) => {
    const { id: hotelID } = useSelector((state) => state.hotelID);
    const [selected, setSelected] = useState(false);

    const subtractOneYear = (dateString) => {
        const date = new Date(dateString);
        date.setFullYear(date.getFullYear() - 1);
        return date;
    };

    if (value !== null)
        return (
            <CustomTooltip
                title={
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={1}
                        m={2}
                        my={1}
                    >
                        <Typography fontSize="1.25em" fontWeight={"bold"}>
                            Same time last year:
                        </Typography>
                        <Typography fontSize="1em" fontWeight={"light"}>
                            {subtractOneYear(fromDay).toLocaleDateString(
                                "en-IE",
                                {
                                    year: "numeric",
                                    month: "short",
                                    day: "2-digit",
                                }
                            )}{" "}
                            -{" "}
                            {subtractOneYear(toDay).toLocaleDateString(
                                "en-IE",
                                {
                                    year: "numeric",
                                    month: "short",
                                    day: "2-digit",
                                }
                            )}
                        </Typography>
                        {!isNaN(valueOld) ? (
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={4}
                            >
                                <Typography fontWeight={"bold"}>
                                    {fns(
                                        hotelID,
                                        valueOld,
                                        {}
                                        // DISPLAY_ELEMENT_HELPER[name].format ?? {}
                                    )}
                                </Typography>
                                <SmallPercentage
                                    usePercentage={false}
                                    brackets={false}
                                    tooltip={false}
                                    bubble={true}
                                    bubbleRound={true}
                                    actual={value - valueOld}
                                    old={1}
                                    text={fns(
                                        hotelID,
                                        parseFloat(
                                            (value - valueOld).toFixed(2)
                                        ),
                                        {}
                                    )}
                                    showFormula={false}
                                    isInverted={
                                        (DISPLAY_ELEMENT_HELPER[name] ?? {})
                                            .upside ?? false
                                    }
                                    styles={{ fontSize: "12px" }}
                                />
                            </Stack>
                        ) : (
                            <Typography
                                fontSize={"1.25em"}
                                fontStyle={"italic"}
                            >
                                No previous value
                            </Typography>
                        )}
                    </Stack>
                }
                onMouseEnter={() => setSelected(true)}
                onMouseLeave={() => setSelected(false)}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={1}
                    p={1}
                    sx={{
                        backgroundColor: selected ? "white" : null,
                        border: selected
                            ? "1px solid lightgrey"
                            : "1px solid transparent",
                        borderRadius: 1,
                    }}
                    // onClick={() => {
                    //     setSelected(!selected);
                    // }}
                >
                    {/* <Tooltip
                        title={
                            (DISPLAY_ELEMENT_HELPER[name] ?? {}).tooltip ?? name
                        }
                    > */}
                    <Typography>
                        {fns(
                            hotelID,
                            getMetricName(name, PLACE) ?? name,
                            // (DISPLAY_ELEMENT_HELPER[name] ?? {}).short ?? name,
                            (DISPLAY_ELEMENT_HELPER[name] ?? {}).headerFormat
                        )}
                    </Typography>
                    {/* </Tooltip> */}
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={4}
                    >
                        <Typography fontWeight={"bold"}>
                            {fns(
                                hotelID,
                                value,
                                {}
                                // DISPLAY_ELEMENT_HELPER[name].format ?? {}
                            )}
                        </Typography>
                        <SmallPercentage
                            brackets={false}
                            tooltip={false}
                            bubble={true}
                            bubbleRound={true}
                            actual={value}
                            old={valueOld}
                            showFormula={false}
                            isInverted={
                                (DISPLAY_ELEMENT_HELPER[name] ?? {}).upside ??
                                false
                            }
                        />
                    </Stack>
                </Stack>
            </CustomTooltip>
        );
    else
        return (
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={0}
            >
                <Skeleton width={100} />

                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={4}
                >
                    <Skeleton width={50} />
                    <Skeleton
                        variant="rounded"
                        sx={{ borderRadius: "45px" }}
                        width={45}
                        height={25}
                    />
                </Stack>
            </Stack>
        );
};

//   _____  _    ____  _     _____
//  |_   _|/ \  | __ )| |   | ____|
//    | | / _ \ |  _ \| |   |  _|
//    | |/ ___ \| |_) | |___| |___
//    |_/_/   \_\____/|_____|_____|
const PLACE = "FiltersTableReport";
const FiltersTableReport = ({
    key,
    data,
    js,
    PDF = false,
    hideExtra = false,
}) => {
    const [js_, setJs_] = useState(js);
    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [openHidePanel, setOpenHidePanel] = useState(false);
    const [group, setGroup] = useState({ keys: [], result: {} });
    const [groupNumber, setGroupNumber] = useState(0);

    useEffect(() => {
        // console.log(js_, Object.keys(js_).length, group);

        if (Object.keys(data).length > 0) {
            if (groupNumber >= Object.keys(data).length) setGroupNumber(0);
            if (groupNumber < Object.values(data).length) {
                setGroup(
                    Object.values(data)[groupNumber] ?? { keys: [], result: {} }
                );
            }
        }
    }, [data, js_, groupNumber]);

    // Uncomment this to easily test email last month
    // useEffect(() => {
    //     let text =
    //         `${group.result.revenueTotal}\n` +
    //         `${group.result.revenueRoom}\n` +
    //         `${group.result.revenueRoomAndExtras}\n` +
    //         `${group.result.revenueExtras}\n` +
    //         `${group.result.bookingsRooms}\n` +
    //         `${group.result.roomNightsTotal}\n` +
    //         `${group.result.bookingsCancelled}\n` +
    //         `${group.result.ABWTotal}\n` +
    //         `${group.result.ALoSTotal}\n` +
    //         `${group.result.ADRTotal}\n` +
    //         `${group.result.revenueVouchers}\n` +
    //         `${group.result.vouchersBookings}\n` +
    //         `${group.result.vouchersCount}\n`;
    //     console.log(text);
    //     navigator.clipboard.writeText(text);
    // }, [group]);

    useEffect(() => {
        setJs_(js);
    }, [js]);

    return (
        <Container
            maxWidth={false}
            component={HoverPaper}
            sx={{ p: 2, backgroundColor: Colours.notificationCard }}
        >
            {Object.keys(data).length > 0 && Object.keys(js_).length > 2 ? (
                <>
                    {Object.values(data).length > 1 && (
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                        >
                            <IconButton
                                size="small"
                                disabled={groupNumber === 0}
                                onClick={() => setGroupNumber(groupNumber - 1)}
                            >
                                <Icons.ArrowLeft />
                            </IconButton>
                            <Typography fontSize="0.8em">
                                {groupNumber + 1}/{Object.values(data).length}
                            </Typography>
                            <IconButton
                                size="small"
                                disabled={
                                    groupNumber >=
                                    Object.values(data).length - 1
                                }
                                onClick={() => setGroupNumber(groupNumber + 1)}
                            >
                                <Icons.ArrowRight />
                            </IconButton>
                            <Grid container columnSpacing={1}>
                                {(group.keys ?? []).map((e) => (
                                    <Grid item>
                                        <Typography fontSize="0.9em" key={e[0]}>
                                            <b>{e[0] ?? "NONE (Voucher)"}</b>:{" "}
                                            {e[1] ?? "NONE (Voucher)"}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Stack>
                    )}
                    {hideExtra ? (
                        <></>
                    ) : (
                        <>
                            <Stack
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "0.8em",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {js_.dataType === DATATYPE_RESDATE
                                        ? "Booked: "
                                        : js_.dataType === DATATYPE_CHECKIN
                                        ? "Checked in: "
                                        : "Checked out: "}
                                    {new Date(js_.fromDay).toLocaleDateString(
                                        "en-IE",
                                        {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                        }
                                    )}{" "}
                                    -{" "}
                                    {new Date(js_.toDay).toLocaleDateString(
                                        "en-IE",
                                        {
                                            year: "numeric",
                                            month: "short",
                                            day: "2-digit",
                                        }
                                    )}
                                </Typography>
                            </Stack>

                            <Divider
                                sx={{
                                    marginY: 2,
                                    borderColor: Colours.navbarBottomLine,
                                }}
                            />
                        </>
                    )}

                    <Grid container rowSpacing={4} columnSpacing={2}>
                        {Object.values(group.result).length === 0 &&
                            [...Array(8).keys()].map((e, i) => (
                                <Grid item key={i} xs={6} md={3}>
                                    <DataElement value={null} />
                                </Grid>
                            ))}
                        {/* Sorted metrics */}
                        {js_.fieldsOrder.map((metric, index) => {
                            if (
                                !CheckHideBool("Vouchers", hotelID) &&
                                ((DISPLAY_ELEMENT_HELPER[metric] ?? {})
                                    .isVoucher ??
                                    false)
                            )
                                return;
                            if (metric === "empty") {
                                return (
                                    <Grid
                                        item
                                        key={metric + index}
                                        xs={6}
                                        md={3}
                                    />
                                );
                            }
                            if (
                                metric in group.result &&
                                !js_.hideFields.includes(metric)
                            ) {
                                return (
                                    <Grid item key={metric} xs={6} md={3}>
                                        <DataElement
                                            name={metric}
                                            value={group.result[metric]}
                                            valueOld={
                                                (group.resultOneYearBefore ??
                                                    {})[metric]
                                            }
                                            percentageDirection={false}
                                            fromDay={js_.fromDay}
                                            toDay={js_.toDay}
                                        />
                                    </Grid>
                                );
                            }
                        })}

                        {/* Not sorted metrics */}
                        {Object.entries(group.result ?? {}).map(
                            ([metric, value]) => {
                                if (
                                    !CheckHideBool("Vouchers", hotelID) &&
                                    (DISPLAY_ELEMENT_HELPER[metric].isVoucher ??
                                        false)
                                )
                                    return;
                                if (
                                    !js_.hideFields.includes(metric) &&
                                    !js_.fieldsOrder.includes(metric) //HERE
                                ) {
                                    return (
                                        <Grid item key={metric} xs={6} md={3}>
                                            <DataElement
                                                name={metric}
                                                value={value}
                                                valueOld={
                                                    (group.resultOneYearBefore ??
                                                        {})[metric]
                                                }
                                                percentageDirection={false}
                                                fromDay={js_.fromDay}
                                                toDay={js_.toDay}
                                            />
                                        </Grid>
                                    );
                                } else return null;
                            }
                        )}
                    </Grid>

                    {!PDF && !hideExtra && <EventsTimeCarousel js={js} />}

                    {hideExtra ? (
                        <></>
                    ) : (
                        <>
                            <Divider
                                sx={{
                                    marginY: 2,
                                    borderColor: Colours.navbarBottomLine,
                                }}
                            />
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    {Array.from({ length: 2 }).map(
                                        (_, index) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    backgroundColor:
                                                        index === 0
                                                            ? `${Colours.trafficLightGreen}20`
                                                            : `${Colours.trafficLightRed}20`,
                                                    color:
                                                        index === 0
                                                            ? "#1B7B3E"
                                                            : "#D11D1D",
                                                    py: ".1em",
                                                    px: ".5em",
                                                    borderRadius: "50px",
                                                    mr: ".5em",
                                                }}
                                            >
                                                {index === 0 ? (
                                                    <Icons.ArrowFullUp
                                                        sx={{
                                                            fontSize: "0.9em",
                                                        }}
                                                    />
                                                ) : (
                                                    <Icons.ArrowFullDown
                                                        sx={{
                                                            fontSize: "0.9em",
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )
                                    )}
                                    <Typography
                                        sx={{ fontSize: "0.8em", ml: "2em" }}
                                    >
                                        Comparison is versus the same time last
                                        year
                                    </Typography>
                                    <InfoIcon
                                        size="75%"
                                        margin={2}
                                        text={`The comparison percentage is calculated by taking the (selected period minus same time last year) divide by same time last year x 100.

    For example:
        Revenue:
            Selected period = €26915                
            Same time last year = €30500
            (€26915 - €30500)/€30500 x 100 = -12%`}
                                    />
                                </Stack>
                                <CheckShow rolesThatCanSee={ARO_ADMIN}>
                                    <Button
                                        startIcon={<Icons.EyeOff />}
                                        onClick={() => {
                                            setOpenHidePanel(true);
                                        }}
                                    >
                                        {js_.hideFields.length}
                                    </Button>
                                </CheckShow>
                            </Stack>
                        </>
                    )}
                </>
            ) : (
                <Table>
                    <TableBody>
                        <NoDataRow text="No data for these filters" />
                    </TableBody>
                </Table>
            )}

            <Dialog
                maxWidth="lg"
                onClose={() => setOpenHidePanel(false)}
                open={openHidePanel}
            >
                <DialogTitle>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography variant="h6">Show/Hide fields </Typography>
                        <Tooltip title="Clean filters">
                            <IconButton
                                onClick={() => {
                                    setJs_({
                                        ...js_,
                                        hideFields: [],
                                    });
                                }}
                            >
                                <Icons.Clean />
                            </IconButton>
                        </Tooltip>
                        <IconButton onClick={() => setOpenHidePanel(false)}>
                            <Icons.CloseNavbar />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {Object.entries(group.result ?? {}).map(
                            ([metric, value]) => (
                                <ListItem disablePadding key={metric}>
                                    <ListItemButton
                                        onClick={() => {
                                            js_.hideFields.includes(metric)
                                                ? setJs_({
                                                      ...js_,
                                                      hideFields: [
                                                          ...js_.hideFields.filter(
                                                              (e) =>
                                                                  e !== metric
                                                          ),
                                                      ],
                                                  })
                                                : setJs_({
                                                      ...js_,
                                                      hideFields: [
                                                          ...js_.hideFields,
                                                          metric,
                                                      ],
                                                  });
                                        }}
                                    >
                                        <ListItemIcon>
                                            {js_.hideFields.includes(metric) ? (
                                                <Icons.EyeOff />
                                            ) : (
                                                <Icons.EyeOn color="primary" />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontWeight:
                                                            DISPLAY_ELEMENT_HELPER[
                                                                metric
                                                            ].important
                                                                ? "bold"
                                                                : "normal",
                                                    }}
                                                >
                                                    {DISPLAY_ELEMENT_HELPER[
                                                        metric
                                                    ].short ?? metric}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            )
                        )}
                    </List>
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default FiltersTableReport;
