import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    useMediaQuery,
    useTheme,
    Box,
    Typography,
    Stack,
} from "@mui/material";

import HoverPaper from "./shared/HoverPaper";

import TableHeadSort from "./TableHeadSort";

import Colours from "../helpers/colours";
import { fns } from "../helpers/common";
import { GB_NotDate, DISPLAY_ELEMENT_HELPER } from "../helpers/filters";
import SmallPercentage from "./SmallPercentage";

const OverviewTable = ({ js, data, PDF = false }) => {
    const theme = useTheme();
    let tableOverflow = useMediaQuery(theme.breakpoints.down("md"));

    const { id: hotelID } = useSelector((state) => state.hotelID);

    const [processedData, setProcessedData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("bookings");
    const [processBy, setProcessBy] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [fields, setFields] = useState([]);

    useEffect(() => {
        setProcessedData([]);
        setSelectedData([]);
        setProcessBy("");
        setLoaded(false);

        if (js === undefined) return;

        if (
            !js.hasOwnProperty("groupBy") ||
            js.groupBy.length !== 1 ||
            Object.entries(data).length === 0
        )
            return;

        let processBy = GB_NotDate(js)[0];
        setProcessBy(processBy);

        let aux_fields = [
            ...js.fieldsOrder
                .filter((f) => !js.hideFields.includes(f))
                .map((field) => {
                    return {
                        id: field,
                        label: DISPLAY_ELEMENT_HELPER[field].abbreviation
                            ? DISPLAY_ELEMENT_HELPER[field].abbreviation
                            : DISPLAY_ELEMENT_HELPER[field].short,
                        styles: { ...headerStyles },
                        format: DISPLAY_ELEMENT_HELPER[field].format ?? {},
                        headerFormat:
                            DISPLAY_ELEMENT_HELPER[field].headerFormat ?? {},
                    };
                }),
            ...Object.keys(DISPLAY_ELEMENT_HELPER)
                .filter(
                    (f) =>
                        !js.fieldsOrder.includes(f) &&
                        !js.hideFields.includes(f)
                )
                .map((field) => {
                    return {
                        id: field,
                        label: DISPLAY_ELEMENT_HELPER[field].abbreviation
                            ? DISPLAY_ELEMENT_HELPER[field].abbreviation
                            : DISPLAY_ELEMENT_HELPER[field].short,
                        styles: { ...headerStyles },
                        format: DISPLAY_ELEMENT_HELPER[field].format ?? {},
                    };
                }),
        ];
        setOrderBy(aux_fields[0].id);

        let auxProcessedData = [];
        Object.entries(data).map(([key, objs]) => {
            if (!key) return;

            let processedObj = { [`${processBy}`]: key, ...objs["result"] };

            if (objs["resultOneYearBefore"]) {
                Object.keys(objs["resultOneYearBefore"]).forEach((prop) => {
                    processedObj[`${prop}YearBefore`] =
                        objs["resultOneYearBefore"][prop];
                });
            }

            auxProcessedData.push(processedObj);
        });

        auxProcessedData.sort((a, b) => b.bookings - a.bookings);

        let all_fields = [];
        aux_fields.forEach((field) => {
            all_fields.push({ id: field.id }, { id: `${field.id}YearBefore` });
        });

        const totalRow = auxProcessedData.reduce(
            (acc, curr) => {
                all_fields.forEach((field) => {
                    if (curr.hasOwnProperty(field.id)) {
                        acc[field.id] = (acc[field.id] || 0) + curr[field.id];
                    }
                });

                return acc;
            },
            {
                [processBy]: "Total",
            }
        );

        totalRow["ADRTotal"] =
            totalRow["revenueRoom"] / totalRow["roomNightsTotal"];

        totalRow["ADRTotalYearBefore"] =
            totalRow["revenueRoomYearBefore"] /
            totalRow["roomNightsTotalYearBefore"];

        totalRow["ALoSTotal"] =
            totalRow["roomNightsTotal"] / totalRow["bookingsNotCancelled"];

        totalRow["ALoSTotalYearBefore"] =
            totalRow["roomNightsTotalYearBefore"] /
            totalRow["bookingsNotCancelledYearBefore"];

        totalRow["ABWTotal"] =
            totalRow["BWTotal"] / totalRow["bookingsNotCancelled"];

        totalRow["ABWTotalYearBefore"] =
            totalRow["BWTotalYearBefore"] /
            totalRow["bookingsNotCancelledYearBefore"];

        // [
        //     "ADRTotal",
        //     "ADRTotalYearBefore",
        //     "ALoS",
        //     "ALoSTotalYearBefore",
        //     "ABWTotal",
        //     "ABWTotalYearBefore",
        // ].forEach((field) => {
        //     if (totalRow.hasOwnProperty(field)) {
        //         totalRow[field] = totalRow[field] / auxProcessedData.length;
        //     }
        // });

        const filtered_fields = aux_fields.filter(
            (item) => item.id !== "BWTotal"
        );
        console.log(filtered_fields);

        setFields(filtered_fields);

        auxProcessedData.push(totalRow);
        setProcessedData(auxProcessedData);
        setLoaded(true);
    }, [data, js]);

    useEffect(() => {
        if (processedData.length === 0) {
            return;
        }
        setSelectedData([]);

        let total = processedData.find((item) => item[processBy] === "Total");
        let auxSelected = processedData.filter(
            (item) => item[processBy] !== "Total"
        );

        auxSelected.sort((a, b) => {
            return order === "asc"
                ? b[orderBy] - a[orderBy]
                : a[orderBy] - b[orderBy];
        });

        if (total) {
            auxSelected.push(total);
        }

        setSelectedData(auxSelected);
    }, [processedData, order, orderBy]);

    const formatLabel = (label, standalone) => {
        switch (label) {
            case "OfferTitle":
                label = "RatePlan";
                break;
            case "RoomName":
                label = "RoomType";
                break;
            default:
                break;
        }

        let labelArray = label.split(/(?=[A-Z])/);

        let formattedLabel =
            (standalone
                ? labelArray[0].charAt(0).toUpperCase() +
                  labelArray[0].slice(1).toLowerCase()
                : labelArray[0].toLowerCase()) +
            (labelArray.length > 1 ? " " + labelArray[1].toLowerCase() : "");

        return formattedLabel;
    };

    const headerStyles = {
        fontSize: "0.8em",
        backgroundColor: Colours.pillarBackgroundAttract,
        textAlign: "left",
        border: "1px solid lightgrey",
    };

    const getCellStyles = (row, processBy, index, order, isSticky) => {
        // TODO: why is not used?
        const isTotalRow = row[processBy] === "Total";
        return {
            position: isSticky ? "sticky" : "relative",
            left: isSticky ? 0 : "auto",
            zIndex: isSticky ? 1 : "auto",
            backgroundColor: isSticky
                ? Colours.plainWhite
                : !isTotalRow
                ? Colours.notificationCard
                : "",
            border: "1px solid lightgrey",

            fontWeight: isTotalRow ? "bold" : "normal",
        };
    };

    const TITLE = "Table Overview";
    return selectedData.length !== 0 ? (
        <>
            <Typography sx={{ fontWeight: "bold", pt: 2 }}>
                Overview by {formatLabel(processBy, false)}
            </Typography>

            <Box
                component={PDF ? "" : HoverPaper}
                sx={{ overflow: "auto", maxHeight: PDF ? "none" : "1000px" }}
            >
                <Table>
                    <TableHeadSort
                        stickyHeads={tableOverflow ? 2 : 0}
                        headers={[
                            {
                                id: `${processBy}`,
                                label: `${formatLabel(processBy, true)}`,
                                ignore: true,
                                styles: {
                                    ...headerStyles,
                                },
                            },
                            ...fields,
                        ]}
                        order={order}
                        orderBy={orderBy}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        colSpan={3}
                    />
                    <TableBody>
                        <TableRow>
                            <TableCell
                                style={{
                                    border: "1px solid lightgrey",
                                    // boxShadow: "inset -.05em -.05em black",
                                }}
                                colSpan={3}
                            ></TableCell>
                            {fields.map(
                                (
                                    h,
                                    i // Info-Empty row
                                ) => (
                                    <React.Fragment key={i}>
                                        <TableCell
                                            style={{
                                                border: "1px solid lightgrey",
                                            }}
                                        >
                                            <Typography variant="caption">
                                                {i === 0 ? "This year" : ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                border: "1px solid lightgrey",
                                                backgroundColor:
                                                    Colours.notificationCard,
                                            }}
                                        >
                                            <Typography variant="caption">
                                                {i === 0 ? "STLY" : ""}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                border: "1px solid lightgrey",
                                                backgroundColor:
                                                    Colours.tableRowBackground,
                                            }}
                                        >
                                            <Typography variant="caption">
                                                {i === 0 ? "Diff" : ""}
                                            </Typography>
                                        </TableCell>
                                    </React.Fragment>
                                )
                            )}
                        </TableRow>

                        {loaded
                            ? selectedData.map(
                                  (row, index) =>
                                      row[processBy] !== "Voucher" && (
                                          <>
                                              <TableRow
                                                  key={`${row[processBy]}`}
                                              >
                                                  <TableCell
                                                      //   fontWeight={"bold"}
                                                      style={{
                                                          fontWeight: "bold",
                                                          border: "1px solid lightgrey",
                                                          ...(tableOverflow && {
                                                              position:
                                                                  "sticky",
                                                              left: 0,
                                                              zIndex: 1,
                                                              backgroundColor:
                                                                  Colours.plainWhite,
                                                          }),
                                                          ...(row[processBy] ===
                                                              "Total" &&
                                                              {
                                                                  //   borderTop:
                                                                  //       "2px solid grey",
                                                                  //   borderBottom:
                                                                  //       "2px solid grey",
                                                              }),
                                                      }}
                                                      colSpan={3}
                                                  >
                                                      {`${row[processBy]}`}
                                                  </TableCell>

                                                  {fields.map((headCell) => (
                                                      <>
                                                          <TableCell
                                                              key={
                                                                  "fields_" +
                                                                  headCell.id
                                                              }
                                                              sx={{
                                                                  textAlign:
                                                                      "right",

                                                                  border: "1px solid lightgrey",
                                                                  ...(row[
                                                                      processBy
                                                                  ] ===
                                                                      "Total" && {
                                                                      fontWeight:
                                                                          "bold",
                                                                      //   borderTop:
                                                                      //       "2px solid grey",
                                                                      //   borderBottom:
                                                                      //       "2px solid grey",
                                                                  }),
                                                              }}
                                                          >
                                                              <Stack
                                                                  direction="row-reverse"
                                                                  justifyContent="space-between"
                                                              >
                                                                  {!isNaN(
                                                                      row[
                                                                          headCell
                                                                              .id
                                                                      ]
                                                                  ) &&
                                                                      fns(
                                                                          hotelID,
                                                                          row[
                                                                              headCell
                                                                                  .id
                                                                          ] ??
                                                                              0,
                                                                          //   headCell.format ??
                                                                          {}
                                                                      )}
                                                              </Stack>
                                                          </TableCell>
                                                          <TableCell
                                                              key={
                                                                  "cell_" +
                                                                  headCell.id
                                                              }
                                                              sx={{
                                                                  textAlign:
                                                                      "right",

                                                                  border: "1px solid lightgrey",

                                                                  backgroundColor:
                                                                      Colours.notificationCard,
                                                                  ...(row[
                                                                      processBy
                                                                  ] ===
                                                                      "Total" && {
                                                                      fontWeight:
                                                                          "bold",

                                                                      //   borderTop:
                                                                      //       "2px solid grey",
                                                                      //   borderBottom:
                                                                      //       "2px solid grey",
                                                                  }),
                                                              }}
                                                          >
                                                              <Stack
                                                                  direction="row-reverse"
                                                                  justifyContent="space-between"
                                                              >
                                                                  {!isNaN(
                                                                      row[
                                                                          `${headCell.id}YearBefore`
                                                                      ]
                                                                  ) &&
                                                                      fns(
                                                                          hotelID,
                                                                          row[
                                                                              `${headCell.id}YearBefore`
                                                                          ] ??
                                                                              0,
                                                                          //   headCell.format ??
                                                                          {}
                                                                      )}
                                                              </Stack>
                                                          </TableCell>
                                                          <TableCell
                                                              key={
                                                                  "cell_" +
                                                                  headCell.id +
                                                                  "_diff"
                                                              }
                                                              sx={{
                                                                  ...(PDF && {
                                                                      paddingLeft: 1,
                                                                      paddingRight: 1,
                                                                  }),
                                                                  textAlign:
                                                                      "right",

                                                                  border: "1px solid lightgrey",

                                                                  backgroundColor:
                                                                      Colours.tableRowBackground,
                                                                  ...(row[
                                                                      processBy
                                                                  ] ===
                                                                      "Total" && {
                                                                      fontWeight:
                                                                          "bold",
                                                                      //   borderTop:
                                                                      //       "2px solid grey",
                                                                      //   borderBottom:
                                                                      //       "2px solid grey",
                                                                  }),
                                                              }}
                                                          >
                                                              <SmallPercentage
                                                                  brackets={
                                                                      false
                                                                  }
                                                                  tooltip={
                                                                      false
                                                                  }
                                                                  bubble={true}
                                                                  bubbleRound={
                                                                      true
                                                                  }
                                                                  actual={
                                                                      row[
                                                                          headCell
                                                                              .id
                                                                      ] ?? 0
                                                                  }
                                                                  actualName="Current period"
                                                                  oldName="Same time last year"
                                                                  old={
                                                                      row[
                                                                          `${headCell.id}YearBefore`
                                                                      ] ?? 0
                                                                  }
                                                                  showFormula={
                                                                      false
                                                                  }
                                                                  //   isInverted={
                                                                  //       (
                                                                  //           DISPLAY_ELEMENT_HELPER[
                                                                  //               name
                                                                  //           ] ?? {}
                                                                  //       ).upside ??
                                                                  //       false
                                                                  //   }
                                                              />
                                                          </TableCell>
                                                      </>
                                                  ))}
                                              </TableRow>
                                          </>
                                      )
                              )
                            : //   _                    _ _
                              //  | |    ___   __ _  __| (_)_ __   __ _
                              //  | |   / _ \ / _` |/ _` | | '_ \ / _` |
                              //  | |__| (_) | (_| | (_| | | | | | (_| |
                              //  |_____\___/ \__,_|\__,_|_|_| |_|\__, |
                              //                                  |___/
                              [
                                  // ...Array(
                                  //     TOP[topNumber] === TOP_ALL
                                  //         ? 25
                                  //         : TOP[topNumber]
                                  // ).keys()

                                  ...Array(3).keys(),
                              ].map((e) => (
                                  <TableRow key={e}>
                                      <TableCell>
                                          <Skeleton />
                                      </TableCell>
                                      <TableCell>
                                          <Skeleton />
                                      </TableCell>
                                      <TableCell>
                                          <Skeleton />
                                      </TableCell>
                                      <TableCell>
                                          <Skeleton />
                                      </TableCell>
                                      <TableCell>
                                          <Skeleton />
                                      </TableCell>
                                  </TableRow>
                              ))}
                    </TableBody>
                </Table>
            </Box>
        </>
    ) : (
        <></>
    );
};

export default OverviewTable;
