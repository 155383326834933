import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import worldGeoJSON from "../../data/world.json";
import * as echarts from "echarts/lib/echarts.js";
import HoverPaper from "../shared/HoverPaper";

import { Box, Table, TableBody, Typography } from "@mui/material";
import { GB_NotDate, GROUP_COUNTRIES } from "../../helpers/filters";
// import Colours from "../../helpers/colours";

import NoDataRow from "../NoDataRow";

import { fns } from "../../helpers/common";

const GeographicalMarketOverview = ({ js, data }) => {
    const [processedData, setProcessedData] = useState([]);
    const [zoomMap, setZoomMap] = useState(1);

    const [maxValue, setMaxValue] = useState(0);

    const { id: hotelID } = useSelector((state) => state.hotelID);

    useEffect(() => {
        setProcessedData([]);

        if (!js || !data || Object.keys(data).length === 0) return;

        // console.log(data);

        const processData = () => {
            const processed = Object.entries(data).map(([key, value]) => ({
                name: key,
                value: value.result.bookingsNotCancelled,
                roomNightsTotal: value.result.roomNightsTotal,
                revenueRoom: value.result.revenueRoom,
            }));
            setProcessedData(processed);

            setMaxValue(
                processed.reduce((max, current) => {
                    return Math.max(max, current["value"]);
                }, -Infinity)
            );
        };

        if (
            js.groupBy &&
            js.groupBy.length === 1 &&
            GB_NotDate(js)[0] === GROUP_COUNTRIES
        ) {
            processData();
        }
    }, [data, js]);

    useEffect(() => {
        if (processedData.length > 0) {
            renderChart();
        }
    }, [processedData, zoomMap]);

    const renderChart = () => {
        echarts.registerMap("world", worldGeoJSON);

        const chart = echarts.init(document.getElementById("main"));

        chart.setOption({
            graphic: [
                {
                    type: "rect",
                    left: "0%",
                    top: "2%",
                    z: 100, // Ensure the buttons are on top of the map
                    shape: {
                        x: 10, // Position from the left
                        y: 10, // Position from the top
                        r: 5, // Corner radius
                        width: 30, // Button width
                        height: 30, // Button height
                    },
                    textContent: {
                        type: "text",
                        z: 101,
                        style: {
                            text: "+", // "+" symbol
                            textAlign: "center",
                            textVerticalAlign: "middle",
                            fill: "#757575", // Text color
                            fontSize: 25, // Font size
                        },
                    },
                    textConfig: { position: "inside" },

                    style: {
                        text: "plus",
                        stroke: "#757575", // Border color
                        lineWidth: 2, // Border width
                        fill: "transparent", // Transparent fill
                    },

                    onclick: function () {
                        let auxZoomIn = zoomMap + 1 >= 10 ? 10 : zoomMap + 1;
                        setZoomMap(auxZoomIn);
                        // console.log("Zoom In");
                    },
                },
                {
                    type: "rect",
                    left: "0%",
                    top: "9%",
                    z: 100, // Ensure the buttons are on top of the map
                    shape: {
                        x: 10, // Position from the left
                        y: 10, // Position from the top
                        r: 5, // Corner radius
                        width: 30, // Button width
                        height: 30, // Button height
                    },
                    textContent: {
                        type: "text",
                        z: 101,
                        style: {
                            text: "-", // "-" symbol
                            textAlign: "center",
                            textVerticalAlign: "middle",
                            fill: "#757575", // Text color
                            fontSize: 30, // Font size
                        },
                    },
                    textConfig: { position: "inside" },

                    style: {
                        text: "plus",
                        stroke: "#757575", // Border color
                        lineWidth: 2, // Border width
                        fill: "transparent", // Transparent fill
                    },

                    onclick: function () {
                        let auxZoomOut = zoomMap - 1 <= 1 ? 1 : zoomMap - 1;
                        setZoomMap(auxZoomOut);
                    },
                },
            ],
            tooltip: {
                trigger: "item",
                formatter: function (params) {
                    return `<span style="font-weight: bold">${
                        params.name
                    }</span> <br />
                            Bookings (after cancellations): ${fns(
                                hotelID,
                                params.value,
                                {}
                            )} <br />
                            Room nights: ${fns(
                                hotelID,
                                params.data.roomNightsTotal,
                                {}
                            )} <br />
                            Room revenue: ${fns(
                                hotelID,
                                params.data.revenueRoom,
                                {}
                            )} `;
                },
                showDelay: 0,
                transitionDuration: 0.2,
            },
            visualMap: {
                type: "piecewise",
                left: "left",
                min: 0,
                max: maxValue,
                inRange: {
                    color: [
                        "#fdf2df", //TODO: move this to colours.js
                        "#faddb1",
                        "#f6c77e",
                        "#f3b04b",
                        "#f09f25",
                    ],
                },
                // splitNumber: 3, // This controls the number of intervals
                pieces: [
                    {
                        min: 0,
                        // max: Math.round(maxValue / 3, 0)
                    },
                    {
                        // min: Math.round(maxValue / 3, 0) + 1,
                        max: Math.round((maxValue / 3) * 2, 0),
                    },
                    {
                        // min: Math.round((maxValue / 3) * 2, 0) + 1,
                        max: maxValue,
                    },
                ],
                precision: 0,
                calculable: true,
                realtime: false,
            },

            series: [
                {
                    name: "Bookings (after cancellations)",
                    type: "map",
                    map: "world",
                    zoom: zoomMap,
                    roam: "move",
                    emphasis: {
                        label: {
                            show: true,
                        },
                    },
                    data: processedData,
                },
            ],
        });

        window.addEventListener("resize", () => {
            chart.resize();
        });

        // console.log("Test");
    };

    return processedData.length > 0 ? (
        <>
            <Box py={2}>
                {" "}
                <Typography sx={{ fontWeight: "bold" }}>
                    Bookings by country
                </Typography>
            </Box>

            <Box
                p={2}
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "15px",
                }}
                component={HoverPaper}
            >
                <Box
                    id="main"
                    style={{
                        width: "100%",
                        height: "500px",
                        backgroundColor: "#f5f5f5",
                    }}
                >
                    {/* Chart will be rendered here */}
                </Box>
            </Box>
        </>
    ) : (
        <>
            <Box py={2}>
                {" "}
                <Typography sx={{ fontWeight: "bold" }}>
                    Bookings by country
                </Typography>
            </Box>

            <Box
                p={2}
                style={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "15px",
                }}
                component={HoverPaper}
            >
                <Table>
                    <TableBody>
                        <NoDataRow text="No data for these filters" />
                    </TableBody>
                </Table>
            </Box>
        </>
    );
};

export default GeographicalMarketOverview;
