import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";

import { darkTheme, lightTheme } from "../theme";
import {
    Box,
    Container,
    Stack,
    ThemeProvider,
    Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import LoginScreen from "../pages/LoginScreen";
import AppScreen from "../pages/AppScreen";
import InsightsAssistScreen from "../pages/InsightsAssistScreen";
import SettingsScreen from "../pages/SettingsScreen";
import ProfileScreen from "../pages/ProfileScreen";
import NotFoundScreen from "../pages/NotFoundScreen";
import DailyReportScreen from "../pages/DailyReportScreen";
import ReleaseScreen from "../pages/ReleaseScreen";
import HotelIdentifierScreen from "../pages/HotelIdentifierScreen";
import MonthlyReportScreen from "../pages/MonthlyReportScreen";
import TargetsScreen from "../pages/TargetsScreen";
import IconsScreen from "../pages/IconsScreen";
import RedAlertsScreen from "../pages/RedAlertsScreen";
import EventsCalendarScreen from "../pages/EventsCalendarScreen";
import EmailListScreen from "../pages/EmailListScreen";
import PDFExportScreen from "../pages/PDFExportScreen";
import IndicatorsScreen from "../pages/IndicatorsScreen";
import EventsAddManyScreen from "../pages/EventsAddManyScreen";
import EmailScreen from "../pages/EmailScreen";
import TestScreen from "../pages/TestScreen";
import EventsImpactScreen from "../pages/EventsImpactScreen";
// import Indicators2Screen from "../pages/Indicators2Screen";
import EmailLogsScreen from "../pages/EmailLogsScreen";

import { loadUserData } from "../actions/auth";
import { setHotelID } from "../actions/hotelID";
import { roles } from "../actions/authRoles";

import NotificationNewVersion from "../components/NotificationNewVersion";
import Track from "../components/Track";
import IsJWTLive from "../components/IsJWTLive";
import Menu from "../components/shared/Menu";
import ProgressBarSteps from "../components/shared/ProgressBarSteps";
import InactivityDetector from "../components/InactivityDetector";
import CheckHide from "../components/shared/CheckHide";
import DataLoader from "../components/DataLoader";
import MarketingStrategy from "../components/MarketingStrategy";

import { AROLOGO, ARO_ADMIN, HOTELS_INFO } from "../configuration";
import Colours from "../helpers/colours";

const DEFAULT_ROUTE = "/app";
const LOGIN_ROUTE = "/login";

const PUBLIC_ROUTES = [
    { path: "/daily/:dailyReportId", element: DailyReportScreen, args: {} },
    {
        path: "/monthly/:monthlyReportId",
        element: MonthlyReportScreen,
        args: { isCollapsible: false },
    },
];
const NOT_LOGGED_ROUTES = [
    { path: "/", element: LoginScreen, args: {} },
    { path: LOGIN_ROUTE, element: LoginScreen, args: {} },
];
const PRIVATE_ROUTES = [
    { path: "/app", element: AppScreen, args: {} },
    { path: "/app/*", element: AppScreen, args: {} },
    { path: "/daily", element: DailyReportScreen, args: {} },
    { path: "/monthly", element: MonthlyReportScreen, args: {} },
    { path: "/insightassist", element: InsightsAssistScreen, args: {} },
    { path: "/settings", element: SettingsScreen, args: {} },
    { path: "/icons", element: IconsScreen, args: {} },
    { path: "/profile", element: ProfileScreen, args: {} },
    {
        path: "/pdfexport",
        element: PDFExportScreen,
        args: {},
    },
    { path: "/indicators/*", element: IndicatorsScreen, args: {} },
    // { path: "/indicators2", element: Indicators2Screen, args: {} },
    { path: "/hotels", element: HotelIdentifierScreen, args: {} },
    { path: "/redalerts", element: RedAlertsScreen, args: {} },
    { path: "/release", element: ReleaseScreen, args: {} },
    { path: "/targets", element: TargetsScreen, args: {} },
    { path: "/eventscalendar", element: EventsCalendarScreen, args: {} },
    { path: "/eventsimpact", element: EventsImpactScreen, args: {} },
    { path: "/emaillist", element: EmailListScreen, args: {} },
    { path: "/email", element: EmailScreen, args: {} },
    { path: "/logs", element: EmailLogsScreen, args: {} },
    { path: "/savedresources", element: MarketingStrategy, args: {} },
    { path: "/test", element: TestScreen, args: {} },
    {
        path: "/eventscalendar/addmanyevents",
        element: EventsAddManyScreen,
        args: {},
    },
    {
        path: "/app/plus/events/addmanyevents",
        element: EventsAddManyScreen,
        args: {},
    },
];

const HIDE_MENU = ["/pdfexport"];

const DelayedNavigate = ({ log, to }) => {
    const [navigate, setNavigate] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!log) {
                setNavigate(true);
            }
        }, 3000);

        return () => clearTimeout(timer);
    }, [log, to]);

    if (navigate) {
        return <Navigate to={to} />;
    }

    return (
        <Container>
            <DataLoader
                Reservations
                SimpleData
                VouchersDetails
                Searches
                Membership
            />
            <br />
            <br />
            <br />
            <br />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ height: "50vh" }}
            >
                <Typography
                    color={Colours.textColour}
                    letterSpacing={4}
                    fontSize={32}
                >
                    WELCOME TO
                </Typography>
                <img width={"400em"} alt="logo" src={AROLOGO} />
                <br />
                <Box width="50%">
                    <ProgressBarSteps steps={[33, 66, 99, 100]} />
                </Box>
            </Stack>
        </Container>
    );
};

// TODO: component that waits a sec if you are not loaded just in case, and if we don't have to token, navigates to default
const AppRouter = () => {
    const [log, setLog] = useState(false);
    const { themeMode } = useSelector((state) => state.preferences);
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth.user) {
            // We already have a registered user, so we set Logged as true
            setLog(true);
        } else {
            // We don't have a user, so we try to load it from the localStorage
            const user = JSON.parse(window.localStorage.getItem("user"));
            if (user) {
                const {
                    expiresOn,
                    // jwt,
                    // user: email,
                    roles: roles_,
                    rolesHotels,
                } = user;
                if (new Date(expiresOn) > new Date()) {
                    dispatch(loadUserData(user));
                    dispatch(roles(roles_, rolesHotels)); // TODO: Move this to function to avoid repeat code from auth.js
                    // When there are many hotels
                    if (rolesHotels.length > 0) {
                        // When not admin or aro
                        if (rolesHotels.filter((el) => el !== -1).length >= 1) {
                            if (
                                !rolesHotels
                                    .map((h) => String(h))
                                    .includes(
                                        window.localStorage.getItem("hotelID")
                                    )
                            ) {
                                dispatch(
                                    setHotelID(
                                        String(
                                            rolesHotels.filter(
                                                (el) => el !== -1
                                            )[0]
                                        )
                                    )
                                );
                            }
                        }
                        // When admin or aro
                        else if (rolesHotels[0] === -1) {
                            if (
                                !Object.keys(HOTELS_INFO).includes(
                                    window.localStorage.getItem("hotelID")
                                )
                            ) {
                                dispatch(setHotelID("default"));
                            }
                        } else {
                        }
                    }
                } else {
                    localStorage.clear();
                }
            }
        }
    }, [auth]);

    return (
        //TODO: make this an array with a map
        <ThemeProvider theme={themeMode === "dark" ? darkTheme : lightTheme}>
            <NotificationNewVersion />
            <CssBaseline />
            <CheckHide rolesThatCannotSee={ARO_ADMIN}>
                <InactivityDetector />
            </CheckHide>
            <Router>
                <Track />
                <IsJWTLive />
                <Routes>
                    {PUBLIC_ROUTES.map(
                        ({ path, element: Component, args }, i) => (
                            <Route
                                path={path}
                                element={<Component {...args} />}
                                key={i}
                            />
                        )
                    )}

                    {NOT_LOGGED_ROUTES.map(
                        ({ path, element: Component, args }, i) => (
                            <Route
                                path={path}
                                element={
                                    log ? (
                                        <Navigate to={DEFAULT_ROUTE} />
                                    ) : (
                                        <Component {...args} />
                                    )
                                }
                                key={i}
                            />
                        )
                    )}
                    {PRIVATE_ROUTES.map(
                        ({ path, element: Component, args }, i) => (
                            <Route
                                path={path}
                                element={
                                    log ? (
                                        <Menu
                                            showSideMenu={
                                                !HIDE_MENU.includes(path)
                                            }
                                        >
                                            <Component {...args} />
                                        </Menu>
                                    ) : (
                                        <DelayedNavigate
                                            log={log}
                                            to={LOGIN_ROUTE}
                                        />
                                    )
                                }
                                key={i}
                            />
                        )
                    )}

                    {/* NOT FOUND */}
                    <Route path="*" element={<NotFoundScreen />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
};

export default AppRouter;
