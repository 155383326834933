import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";

import MonthlyReportScreen from "../pages/MonthlyReportScreen";
import MonthlyKPIsScreen from "../pages/MonthlyKPIsScreen";
import MembershipReportScreen from "../pages/MembershipReportScreen";

import SalesSummary from "../components/SalesSummary";
import CheckShow from "../components/shared/CheckShow";
import FiltersReport from "../components/FiltersReport";
import FiltersReportsArray from "../components/FiltersReportsArray";

import { ARO_ADMIN } from "../configuration";
import { Box, Typography, Grid } from "@mui/material";

const Dashboard = ({ handleProcessedData, processedData }) => (
    <>
        <Grid container>
            <Grid item sx={{ mt: 2, mb: 2 }} xs={12}>
                <Typography variant="h4">Reporting</Typography>
            </Grid>
        </Grid>{" "}
        <FiltersReport setProcessed={handleProcessedData} />
        <FiltersReportsArray />
    </>
);

const AroReportScreen = ({}) => {
    const [processedData, setProcessedData] = useState({});

    const handleProcessedData = (data) => {
        setProcessedData(data);
    };

    return (
        <>
            <Routes>
                <Route
                    path=""
                    element={
                        <Dashboard
                            handleProcessedData={handleProcessedData}
                            processedData={processedData}
                        />
                    }
                />
                <Route
                    path="dashboard"
                    element={
                        <Dashboard
                            handleProcessedData={handleProcessedData}
                            processedData={processedData}
                        />
                    }
                />
                <Route
                    path="summary"
                    element={
                        <>
                            <br />
                            <SalesSummary />
                            <br />
                            <CheckShow rolesThatCanSee={ARO_ADMIN}>
                                <MonthlyReportScreen />
                            </CheckShow>
                        </>
                    }
                />
                <Route path="kpis" element={<MonthlyKPIsScreen isolated />} />
                <Route
                    path="membership"
                    element={
                        <>
                            <br />
                            <MembershipReportScreen />
                        </>
                    }
                />
            </Routes>
        </>
    );
};
export default AroReportScreen;
