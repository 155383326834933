import { hotelIDTypes } from "../types/hotelIDTypes";

const initial = {
    id: localStorage.getItem("hotelID") || "default",
};

export const hotelIDReducer = (state = initial, action) => {
    switch (action.type) {
        case hotelIDTypes.set:
            const newState = action.payload;
            localStorage.setItem("hotelID", newState.id);
            return newState;

        case hotelIDTypes.default:
            return action.payload;

        case hotelIDTypes.clean:
            localStorage.removeItem("hotelID");
            return {};

        default:
            return state;
    }
};
